
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















































































@import './src/styles/base/spacing';

.keypoints-section {
  position: relative;
  z-index: 1; // Create new stacking context, allow to use -1 on background
  color: $c-text-light;
  background: $c-main;

  &__background-container {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%);

    @include mq($until: wrapper) {
      overflow: hidden;
    }
  }
}

.bleed {
  background-color: $c-black;
}

.bleed__visual {
  position: asbolute;
  top: 0;
}

.keypoint {
  @extend .pt-xxs;
  @extend .pb-l;

  &:nth-last-of-type() {
    padding-bottom: 0;
  }

  @include mq($from: s) {
    &:nth-of-type(odd) {
      --progress: 0;

      @media (prefers-reduced-motion) {
        --progress: 1;
      }

      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 1px;
        background: currentColor;
        transform-origin: 0 0;
        transform: scaleX(var(--progress));
      }
    }

    &:nth-last-of-type(-n + 2) {
      padding-bottom: 0;
    }
  }

  &__value {
    margin-bottom: clamp(1rem, 1.19vw, 2rem);
    font-size: clamp(3rem, 3.571vw, 6rem);
    font-weight: 600;
  }

  &__label {
    font-size: 1.5rem;
  }
}
