/**
* Dev, based on $env (from gulp)
*/
$env: 'development' !default;

/**
 * Colors
 * for the naming use http://chir.ag/projects/name-that-color/
 * from darker to lighter ?
 */

// Global colors
$c-black: rgb(0, 0, 0);
$c-almost-black: #101010;
$c-gray-darkest: #2d2d2d;
$c-gray-darker: #5e5e5e;
$c-gray-dark: #858585;
$c-gray-medium: rgb(111, 113, 117);
$c-gray: #b2b2b2;
$c-gray-light: #d8d8d8;
$c-white: rgb(255, 255, 255);
$c-transparent: transparent;
$c-pampas: #f4f3ed;

// Dev colors
$c-dev-bg: #3e4b55;
$c-dev-success: #090;
$c-dev-error: #d90b0b;
$c-dev-warning: #f50;
$c-dev-info: #829deb;
$c-dev-rebecca: rebeccapurple; /* stylelint-disable-line color-named */

// Project colors
$c-surface-dark: $c-black;
$c-surface-light: $c-white;
$c-surface-light-accent: $c-pampas;
$c-logo-dark: $c-almost-black;
$c-logo-light: $c-white;
$c-main: #e10600;
$c-input-bg: #121212;

/*
* Dimensions
*/
$content-width: 2304px;
$actual-content-width: 1920px; // Container minus 4 padding columns
$header-height: 60px;
$header-height-large: 100px;
$header-breakpoint: xl;
$gutter-width: 34px;
$scrollbar-width-default: 15px; // Will be used as default value for CSS var that will be overwritten with javascript

/*
* Typography
*/
$base-font-size: 10px !default;
$c-text-dark: $c-black;
$c-text-light: $c-white;
$ff-default: 'Manrope', sans-serif;
$ff-alt: 'Monument Extended', sans-serif; // TODO: Import font, duh
$body-text: 1.6rem;
$body-text-m: 2rem;

/**
* RWD
*/
$mq-responsive: true;
$mq-static-breakpoint: 'large';

/**
* ANIMATION
*/
$easing: cubic-bezier(0.25, 1, 0.5, 1);
$transition-duration: 400ms;

/**
* MISC
*/
$z-index-header: 10;
$overline-height: 0.6rem;
$header-content-padding: 2.6rem;
$header-sticky-content-padding: 1.2rem;
