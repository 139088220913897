.o-h {
  overflow: hidden;
}

// Stands for Vertical Clip
// Clip content vertically but allow horizontal overflow.
// Fix this issue : https://git.epic.net/bemac/website-v1/track/-/issues/205
.v-c {
  clip-path: inset(0 -100% 0 -100%);
}

.v-h {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
